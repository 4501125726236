import * as React from "react";

import { vars } from "~styles/themes/theme.css";

import { colorTokens_latest } from "~styles/vars/colorTokens_latest";

import type { SVGProps } from "react";

export function SvgLogoCryptosell(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={129}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="m16 0 13.856 8v16L16 32 2.144 24V8L16 0z"
        fill={colorTokens_latest.Secondary.Powder_blue[130].value}
      />
      <path
        d="m12.548 12.21-2.231 3.83.547.969 2.505-4.316-.084-.042a2.136 2.136 0 0 1-.674-.378l-.063-.064z"
        fill={vars.color.neutral.white}
      />
      <path
        d="m20.506 16.336-1.684 2.905h-7.116L8.97 14.525l-.063.063c-.19.169-.421.295-.674.38l-.084.041 3.01 5.2h8.23l1.958-3.368-.841-.505zm-8.021 4.252H11.37l2.273 3.915h3.915v-.968H14.19l-1.705-2.947zm-.779-13.641h7.116l1.726 2.968h1.115L19.39 6h-8.252L9.18 9.368l.842.484 1.684-2.905zm6.253 4.316h3.368l.252.42.063-.063c.19-.168.421-.294.674-.378l.084-.043-.526-.905h-3.915v.969zm4.273 10.904c.252.084.463.21.674.379l.063.063 3.031-5.2-1.663-2.884-.063.063c-.19.169-.421.295-.674.38l-.084.041 1.39 2.4-2.737 4.716.063.042z"
        fill={vars.color.neutral.white}
      />
      <path
        d="M15.495 10.78a1.59 1.59 0 0 0-1.578-1.58 1.59 1.59 0 0 0-1.58 1.58 1.59 1.59 0 0 0 1.58 1.578c.863 0 1.578-.694 1.578-1.579zm-1.578-.61a.61.61 0 1 1-.001 1.22.61.61 0 0 1 0-1.22zm7.663 12.27A1.59 1.59 0 0 0 20 24.017a1.59 1.59 0 0 0 1.58 1.579 1.59 1.59 0 0 0 1.578-1.579c0-.863-.695-1.579-1.579-1.579zm0 2.189a.61.61 0 1 1 .61-.61c.02.336-.274.61-.61.61zM8.949 13.893c.21-.358.273-.779.147-1.2a1.522 1.522 0 0 0-.737-.947 1.658 1.658 0 0 0-.779-.21c-.568 0-1.094.294-1.368.778a1.56 1.56 0 0 0 .59 2.147c.231.127.505.211.778.211.569 0 1.095-.295 1.369-.779zm-.821-.484a.595.595 0 0 1-.38.295.593.593 0 0 1-.462-.064.62.62 0 0 1 .294-1.157.62.62 0 0 1 .547.926zm13.451-1.095c-.21.358-.274.78-.148 1.2.106.4.38.758.737.947.232.127.506.211.78.211.568 0 1.094-.295 1.367-.779.21-.358.274-.779.148-1.2a1.522 1.522 0 0 0-.737-.947 1.658 1.658 0 0 0-.779-.21c-.568 0-1.094.294-1.368.778zm1.895 1.095a.595.595 0 0 1-.38.294.58.58 0 0 1-.462-.063.595.595 0 0 1-.295-.379.593.593 0 0 1 .063-.463.62.62 0 0 1 .526-.315c.106 0 .21.02.316.084.147.084.253.21.295.379a.58.58 0 0 1-.063.463z"
        fill={vars.color.neutral.white}
      />
      <path
        d="M36.88 16.26c0 3.64 2.88 5.98 6.56 5.98 1.74 0 3.96-.62 5.48-2.56l-2.18-1.5c-.76.94-2 1.54-3.14 1.54-2.3 0-3.76-1.56-3.76-3.64 0-2.92 2.14-5.12 4.66-5.12 1.54 0 2.8.78 3.32 2.18l2.74-.92c-.78-2.02-2.66-3.78-5.92-3.78-4.16 0-7.76 3.1-7.76 7.82zM49.91 22h2.54l1.38-6.32c.58-.64 1.48-1.16 2.4-1.16.42 0 .78.08.94.12l.54-2.52c-1.52 0-2.56.58-3.42 1.5l.3-1.28h-2.54L49.91 22zm6.908 1.48-.84 2.2c.46.16 1.06.24 1.62.24 1.6 0 2.68-.62 3.74-2.34l7.04-11.24h-2.8l-4.04 6.72-1.02-6.72h-2.64l1.72 9.72-.6.9c-.34.5-.68.68-1.2.68-.36 0-.76-.06-.98-.16zm13.373-2.76c.64.96 1.76 1.52 3.08 1.52 3.26 0 5.08-3.24 5.08-6.14 0-2.56-1.5-4-3.66-4-1.16 0-2.1.48-2.92 1.44l.28-1.2h-2.54l-2.96 13.34h2.54l1.1-4.96zm5.5-4.1c0 1.78-1.16 3.36-2.84 3.36-1.02 0-1.84-.46-2.26-1.1l.74-3.36c.54-.66 1.32-1.16 2.22-1.16 1.22 0 2.14.88 2.14 2.26zm4.34 3.52c0 1.44 1.16 2.1 2.88 2.1.62 0 1.22-.16 1.6-.42l-.1-2.04c-.12.1-.44.2-.7.2-.64 0-.94-.32-.94-.84 0-.12.02-.28.04-.4l.92-4.2h1.96l.52-2.2h-1.96l.58-2.64h-2.56l-.58 2.64h-1.6l-.5 2.2h1.6l-1.08 4.94c-.06.24-.08.52-.08.66zm6.118-2.28c0 2.52 1.7 4.38 4.78 4.38 3.28 0 5.6-2.74 5.6-5.74 0-2.52-1.72-4.4-4.8-4.4-3.26 0-5.58 2.74-5.58 5.76zm7.74-1.24c0 1.74-1.16 3.36-2.86 3.36-1.42 0-2.26-.86-2.26-2.26 0-1.72 1.16-3.36 2.88-3.36 1.4 0 2.24.86 2.24 2.26zm3.025 3.88c1.08 1.14 2.8 1.74 4.56 1.74 2.64 0 4.18-1.54 4.18-3.38 0-1.74-1.82-2.36-3.24-2.8-.94-.32-1.66-.62-1.66-1.08 0-.52.5-.94 1.4-.94 1.18 0 2.38.64 2.86 1.24l1.26-1.6c-.9-.96-2.44-1.58-4.08-1.58-2.58 0-3.98 1.58-3.98 3.26 0 1.64 1.74 2.26 3.12 2.72.96.32 1.78.62 1.78 1.2 0 .56-.54 1.02-1.4 1.02-1.4 0-2.82-.8-3.4-1.48l-1.4 1.68zm10.271-2.6c0 2.7 1.96 4.34 5 4.34 1.2 0 2.48-.36 3.42-1l-.8-1.74c-.6.46-1.6.72-2.44.72-1.78 0-2.64-1.18-2.64-2v-.24h7.18c.1-.32.2-1 .2-1.56 0-2.7-1.88-4.32-4.34-4.32-3.22 0-5.58 2.66-5.58 5.8zm7.64-1.64h-4.84c.22-1.06 1.26-2.14 2.62-2.14 1.54 0 2.22.98 2.22 1.94v.2zM117.86 22h2.54l2.94-13.34h-2.54L117.86 22zm5.058 0h2.54l2.94-13.34h-2.54L122.918 22z"
        fill={vars.color.neutral.black}
      />
    </svg>
  );
}
