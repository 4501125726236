import * as React from "react";

import type { SVGProps } from "react";

export function SvgLogoMockClient(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.429 13.714 4.57 20.57l2.286 2.285L13.714 16l-2.285-2.286Zm2.285 16L16 32l16-16-2.285-2.286-16 16ZM22.858 16l-2.286-2.286L9.143 25.142l2.286 2.286L22.857 16Z"
        fill="#1F1F25"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m0 16 2.286 2.286L16 4.572l9.143 9.142 2.285-2.286L16 0A215499.21 215499.21 0 0 0 0 16Z"
        fill="#1F1F25"
      />
    </svg>
  );
}
