import * as React from "react";

import type { SVGProps } from "react";

export function SvgLogoEthereum(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#ethereum_logo_svg__a)">
        <path
          d="m15.997 0-.214.73v21.158l.214.214 9.807-5.805L15.997 0Z"
          fill="#343434"
        />
        <path d="M15.997 0 6.19 16.297l9.807 5.805V0Z" fill="#8C8C8C" />
        <path
          d="m15.997 23.962-.12.147v7.537l.12.354 9.813-13.84-9.813 5.802Z"
          fill="#3C3C3B"
        />
        <path d="M15.997 32v-8.038L6.19 18.159 15.997 32Z" fill="#8C8C8C" />
        <path
          d="m15.997 22.102 9.807-5.805-9.807-4.465v10.27Z"
          fill="#141414"
        />
        <path d="m6.19 16.297 9.807 5.805v-10.27L6.19 16.297Z" fill="#393939" />
      </g>
      <defs>
        <clipPath id="ethereum_logo_svg__a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
