import * as React from "react";

import type { SVGProps } from "react";

export function SvgLogoSolana(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#solana_logo_svg__a)">
        <path
          d="M5.198 22.608c.193-.194.458-.306.74-.306h25.539c.467 0 .7.564.37.894l-5.045 5.05a1.046 1.046 0 0 1-.74.305H.522a.524.524 0 0 1-.37-.893l5.046-5.05Z"
          fill="url(#solana_logo_svg__b)"
        />
        <path
          d="M5.198 3.754c.201-.193.467-.306.74-.306h25.539c.467 0 .7.564.37.894l-5.045 5.05a1.047 1.047 0 0 1-.74.306H.523a.524.524 0 0 1-.37-.894l5.045-5.05Z"
          fill="url(#solana_logo_svg__c)"
        />
        <path
          d="M26.802 13.12a1.046 1.046 0 0 0-.74-.305H.523c-.467 0-.7.564-.37.894l5.045 5.05c.193.193.459.305.74.305h25.539c.467 0 .7-.563.37-.894l-5.045-5.05Z"
          fill="url(#solana_logo_svg__d)"
        />
      </g>
      <defs>
        <linearGradient
          id="solana_logo_svg__b"
          x1={29.037}
          y1={0.431}
          x2={11.336}
          y2={34.304}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset={1} stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="solana_logo_svg__c"
          x1={21.309}
          y1={-3.607}
          x2={3.608}
          y2={30.266}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset={1} stopColor="#DC1FFF" />
        </linearGradient>
        <linearGradient
          id="solana_logo_svg__d"
          x1={25.148}
          y1={-1.6}
          x2={7.448}
          y2={32.272}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#00FFA3" />
          <stop offset={1} stopColor="#DC1FFF" />
        </linearGradient>
        <clipPath id="solana_logo_svg__a">
          <path
            fill="#fff"
            transform="translate(0 3.448)"
            d="M0 0h32v25.103H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
