import * as React from "react";

import type { SVGProps } from "react";

export function SvgLogoVisa(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={36}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#visa_svg__a)">
        <path
          d="M0 3a3 3 0 0 1 3-3h30a3 3 0 0 1 3 3v18a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3z"
          fill="#D8E3F3"
        />
        <path
          d="M30.982 7.526h-1.886c-.57 0-1.008.176-1.271.746l-3.597 8.202h2.544s.439-1.097.526-1.36h3.114c.088.307.307 1.316.307 1.316H33l-2.018-8.904zM28 13.272c.22-.526.965-2.5.965-2.5 0 .044.22-.526.307-.833l.175.79s.483 2.148.57 2.587H28v-.044zm-3.596.263c0 1.842-1.667 3.07-4.255 3.07-1.096 0-2.149-.22-2.72-.482l.352-2.018.307.132c.79.35 1.316.482 2.28.482.702 0 1.448-.263 1.448-.877 0-.395-.307-.658-1.272-1.097-.921-.438-2.15-1.14-2.15-2.412 0-1.754 1.711-2.938 4.124-2.938.92 0 1.71.175 2.193.394l-.351 1.93-.176-.175a4.713 4.713 0 0 0-1.842-.351c-.92.044-1.36.438-1.36.79 0 .394.527.701 1.36 1.096 1.404.658 2.062 1.403 2.062 2.456z"
          fill="#3362AB"
        />
        <path
          d="m3 7.614.043-.176h3.772c.527 0 .921.176 1.053.746l.833 3.948C7.868 10.026 5.938 8.316 3 7.614z"
          fill="#F9B50B"
        />
        <path
          d="m14.009 7.526-3.816 8.904H7.605L5.412 8.974c1.58 1.008 2.895 2.587 3.378 3.684l.263.92 2.368-6.096h2.588v.044zm1.008-.044h2.413l-1.535 8.948h-2.413l1.535-8.948z"
          fill="#3362AB"
        />
      </g>
      <defs>
        <clipPath id="visa_svg__a">
          <path fill="#fff" d="M0 0h36v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
