import * as React from "react";

import type { SVGProps } from "react";

export function SvgLogoBitcoin(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#bitcoin_logo_svg__a)">
        <path
          d="M31.521 19.87c-2.137 8.572-10.82 13.789-19.393 11.651C3.558 29.384-1.66 20.702.479 12.131 2.615 3.56 11.298-1.658 19.869.48c8.572 2.137 13.79 10.82 11.652 19.392Z"
          fill="#F7931A"
        />
        <path
          d="M23.056 13.72c.319-2.129-1.303-3.273-3.52-4.037l.72-2.884L18.5 6.36l-.7 2.809a72.62 72.62 0 0 0-1.407-.331l.705-2.827-1.755-.438-.72 2.884a58.266 58.266 0 0 1-1.12-.264l.001-.009-2.421-.605-.467 1.876s1.302.298 1.275.317c.711.177.84.648.818 1.02l-.819 3.287c.049.012.112.03.182.058l-.185-.046-1.148 4.603c-.087.216-.308.54-.805.417.018.026-1.276-.318-1.276-.318l-.872 2.01 2.285.57c.425.106.842.217 1.252.322l-.727 2.917 1.754.438.72-2.886c.479.13.944.25 1.4.363l-.718 2.873 1.756.437.727-2.912c2.994.567 5.245.338 6.193-2.37.764-2.18-.038-3.438-1.613-4.258 1.147-.264 2.011-1.019 2.242-2.577h-.001Zm-4.012 5.626c-.543 2.18-4.214 1.001-5.404.706l.964-3.865c1.19.297 5.007.885 4.44 3.159Zm.543-5.657c-.495 1.983-3.55.976-4.542.728l.874-3.505c.992.247 4.184.708 3.668 2.777Z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="bitcoin_logo_svg__a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
