import * as React from "react";

import type { SVGProps } from "react";

export function SvgLogoCronos(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={32}
      height={37}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#cronos_logo_svg__a)">
        <path
          d="M16.02.001 0 9.235V27.7l16.02 9.234L32.04 27.7V9.235L16.02 0Z"
          fill="url(#cronos_logo_svg__b)"
        />
        <path
          d="M16.02.001 0 9.235V27.7l16.02 9.234V0Z"
          fill="url(#cronos_logo_svg__c)"
        />
        <path
          d="M22.266 7.98H9.693l-1.46 6.398h15.574l-1.54-6.398ZM11.924 23.166v-4.252l-3.731-2.349-4.218 3.118 5.759 10.003h2.312l2.717-2.551v-1.256l-2.84-2.713Z"
          fill="#fff"
        />
        <path
          d="M20.076 15.35h-8.112l1.339 3.564-.406 4.009h6.246l-.405-4.01 1.338-3.563Z"
          fill="#fff"
        />
        <path
          d="m23.807 16.524-3.69 2.39v4.252l-2.799 2.713v1.256l2.717 2.51h2.272l5.718-9.962-4.218-3.159Z"
          fill="#fff"
        />
      </g>
      <defs>
        <linearGradient
          id="cronos_logo_svg__b"
          x1={16.006}
          y1={0.001}
          x2={16.006}
          y2={36.912}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#25376C" />
          <stop offset={1} stopColor="#1F1F49" />
        </linearGradient>
        <linearGradient
          id="cronos_logo_svg__c"
          x1={8.004}
          y1={36.912}
          x2={8.004}
          y2={0.001}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#25376C" />
          <stop offset={1} stopColor="#1F1F49" />
        </linearGradient>
        <clipPath id="cronos_logo_svg__a">
          <path fill="#fff" d="M0 0h32v36.896H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}
