import * as React from "react";

import type { SVGProps } from "react";

export function SvgLogoIRollerDice(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={98}
      height={56}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M34 10.5 44.5 0 55 10.5 44.5 21 34 10.5zm11.625 0A1.14 1.14 0 0 0 44.5 9.375a1.11 1.11 0 0 0-1.125 1.125c0 .656.469 1.125 1.125 1.125a1.11 1.11 0 0 0 1.125-1.125zM38.5 11.625a1.11 1.11 0 0 0 1.125-1.125A1.14 1.14 0 0 0 38.5 9.375a1.11 1.11 0 0 0-1.125 1.125c0 .656.469 1.125 1.125 1.125zm6 3.75a1.11 1.11 0 0 0-1.125 1.125c0 .656.469 1.125 1.125 1.125a1.11 1.11 0 0 0 1.125-1.125 1.14 1.14 0 0 0-1.125-1.125zm6-3.75a1.11 1.11 0 0 0 1.125-1.125A1.14 1.14 0 0 0 50.5 9.375a1.11 1.11 0 0 0-1.125 1.125c0 .656.469 1.125 1.125 1.125zm-6-8.25A1.11 1.11 0 0 0 43.375 4.5c0 .656.469 1.125 1.125 1.125A1.11 1.11 0 0 0 45.625 4.5 1.14 1.14 0 0 0 44.5 3.375zM49 18.656l7.031-7.078L57.11 10.5l-1.078-1.031L55.61 9H64v15H49v-5.344zm8.625-2.156a1.14 1.14 0 0 0-1.125-1.125 1.11 1.11 0 0 0-1.125 1.125c0 .656.469 1.125 1.125 1.125a1.11 1.11 0 0 0 1.125-1.125z"
        fill="#F09000"
      />
      <path
        d="M2.52 37.3c.84 0 1.52-.68 1.52-1.52s-.68-1.5-1.52-1.5c-.82 0-1.52.66-1.52 1.5s.7 1.52 1.52 1.52zM3.8 48v-9.66H1.26V48H3.8zm13.379 0-3-5.08c1.44-.34 2.92-1.6 2.92-3.96 0-2.48-1.7-4.3-4.48-4.3h-6.24V48h2.84v-4.74h2.08l2.62 4.74h3.26zm-4.98-7.24h-2.98v-3.6h2.98c1.14 0 2 .68 2 1.8s-.86 1.8-2 1.8zm10.846 7.48c3.2 0 5.12-2.32 5.12-5.08 0-2.74-1.92-5.06-5.12-5.06-3.18 0-5.1 2.32-5.1 5.06 0 2.76 1.92 5.08 5.1 5.08zm0-2.26c-1.58 0-2.46-1.3-2.46-2.82 0-1.5.88-2.8 2.46-2.8s2.48 1.3 2.48 2.8c0 1.52-.9 2.82-2.48 2.82zM32.589 48V34.66h-2.54V48h2.54zm5.059 0V34.66h-2.54V48h2.54zm7.058.24c1.5 0 3.02-.46 3.98-1.34l-1.12-1.64c-.62.6-1.76.96-2.58.96-1.64 0-2.62-1.02-2.78-2.24h7.18v-.56c0-3.16-1.96-5.32-4.84-5.32-2.94 0-5 2.26-5 5.06 0 3.1 2.22 5.08 5.16 5.08zm2.22-5.98h-4.76c.12-.96.8-2.14 2.38-2.14 1.68 0 2.32 1.22 2.38 2.14zM53.761 48v-6.38c.42-.62 1.54-1.1 2.38-1.1.28 0 .52.02.7.06V38.1c-1.2 0-2.4.7-3.08 1.56v-1.32h-2.54V48h2.54zm9.987 0c4.18 0 7.08-2.64 7.08-6.66s-2.9-6.68-7.08-6.68h-5.26V48h5.26zm0-2.5h-2.42v-8.34h2.42c2.74 0 4.18 1.82 4.18 4.18 0 2.26-1.54 4.16-4.18 4.16zm10.295-8.2c.84 0 1.52-.68 1.52-1.52s-.68-1.5-1.52-1.5c-.82 0-1.52.66-1.52 1.5s.7 1.52 1.52 1.52zm1.28 10.7v-9.66h-2.54V48h2.54zm7.019.24c1.98 0 3.18-.86 3.82-1.76l-1.66-1.54c-.46.64-1.16 1.04-2.04 1.04-1.54 0-2.62-1.14-2.62-2.82s1.08-2.8 2.62-2.8c.88 0 1.58.36 2.04 1.04l1.66-1.56c-.64-.88-1.84-1.74-3.82-1.74-2.98 0-5.12 2.1-5.12 5.06 0 2.98 2.14 5.08 5.12 5.08zm10.02 0c1.5 0 3.02-.46 3.98-1.34l-1.12-1.64c-.62.6-1.76.96-2.58.96-1.64 0-2.62-1.02-2.78-2.24h7.18v-.56c0-3.16-1.96-5.32-4.84-5.32-2.94 0-5 2.26-5 5.06 0 3.1 2.22 5.08 5.16 5.08zm2.22-5.98h-4.76c.12-.96.8-2.14 2.38-2.14 1.68 0 2.32 1.22 2.38 2.14z"
        fill="#1F1F25"
      />
    </svg>
  );
}
